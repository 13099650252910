const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://todossomosvago.com' : 'http://192.168.100.106:3074',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://todossomosvago.com' : 'http://192.168.100.106:3074',
    api: 'api/',
    apiSocket: 'vagomx::1.2',
    nameDir: 'vagomx',
    package: 'app.dvgeo.vagomx.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyB5F2_7EpUokzAXbKUFiMEzOn3-IMPQUho',
    appName: 'VAGO MX',
    provider: '',
    colorPrimary: '#F90307',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.todossomosvago.passenger',
    playStoreDriverId: 'com.todossomosvago.driver',
    appStorePassengerId: '6476521141',
    appStoreDriverId: '6476521749',
    email: "administracion@vagomx.com",
};
export default config;
